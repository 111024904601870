export const defaultSetting = {
  slideWidth: 220,
  languages: [
    {
      key: "zh",
      name: "5iwzry",
    },
    {
      key: "en",
      name: "hGtEfNnp",
    },
  ],
  defaultLang: "zh",
};
