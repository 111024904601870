var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
var __objRest = (source, exclude) => {
  var target = {};
  for (var prop in source)
    if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0)
      target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(source)) {
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop))
        target[prop] = source[prop];
    }
  return target;
};

// src/Button/Button.tsx
import classNames from "classnames";
import React from "react";
var Button = (_a) => {
  var _b = _a, {
    className
  } = _b, props = __objRest(_b, [
    "className"
  ]);
  return /* @__PURE__ */ React.createElement("button", __spreadValues({ className: classNames("sk-button", className) }, props));
};
var Button_default = Button;

// src/Button/index.tsx
var Button_default2 = Button_default;

// src/Divider/Divider.tsx
import classNames2 from "classnames";
import React2 from "react";
var Divider = (_a) => {
  var _b = _a, { className } = _b, props = __objRest(_b, ["className"]);
  return /* @__PURE__ */ React2.createElement("div", __spreadValues({ className: classNames2("sk-divider", className) }, props));
};
var Divider_default = Divider;

// src/Divider/index.tsx
var Divider_default2 = Divider_default;

// src/VideoPlayer/VideoPlayer.tsx
import React3, { forwardRef, useEffect, useRef, useState } from "react";
var VideoPlayer = forwardRef(
  (props, ref) => {
    const _a = props, { src, width = "100%", height = "88vh", showControls = true, autoPlay = false } = _a, rest = __objRest(_a, ["src", "width", "height", "showControls", "autoPlay"]);
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(autoPlay);
    const [volume, setVolume] = useState(0.5);
    const [progress, setProgress] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [currentTime, setCurrentTime] = useState("00:00");
    const [duration, setDuration] = useState("00:00");
    const [isPictureInPicture, setIsPictureInPicture] = useState(false);
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      const minutesStr = String(minutes).padStart(2, "0");
      const secondsStr = String(seconds).padStart(2, "0");
      return `${minutesStr}:${secondsStr}`;
    };
    useEffect(() => {
      if (videoRef.current) {
        const videoElement = videoRef.current;
        const handleTimeUpdate = () => {
          const currentTimeFormatted = formatTime(videoElement.currentTime);
          setCurrentTime(currentTimeFormatted);
          const durationFormatted = formatTime(videoElement.duration);
          setDuration(durationFormatted);
        };
        const handleError = (event) => {
          const errorEvent = event;
          const errorMessage = errorEvent.target.error.message;
          console.error(`Video error: ${errorMessage}`);
          setIsPlaying(false);
        };
        videoElement.addEventListener("timeupdate", handleTimeUpdate);
        videoElement.addEventListener("error", handleError);
        return () => {
          videoElement.removeEventListener("timeupdate", handleTimeUpdate);
          videoElement.removeEventListener("error", handleError);
        };
      }
    }, []);
    useEffect(() => {
      if (videoRef.current && autoPlay) {
        videoRef.current.play();
      }
    }, [autoPlay]);
    const handlePlayPause = () => {
      if (videoRef.current) {
        if (isPlaying)
          videoRef.current.pause();
        else
          videoRef.current.play();
        setIsPlaying(!isPlaying);
        if (rest.onPlayPause)
          rest.onPlayPause();
      }
    };
    const handleReload = () => {
      if (videoRef.current) {
        videoRef.current.load();
        setIsPlaying(false);
        setProgress(0);
        setCurrentTime("00:00");
        setDuration("00:00");
        if (rest.onReload)
          rest.onReload();
      }
    };
    const handleFastForward = () => {
      if (videoRef.current) {
        videoRef.current.currentTime += 3;
        setProgress(
          videoRef.current.currentTime / videoRef.current.duration * 100
        );
        if (rest.onFastForward)
          rest.onFastForward();
      }
    };
    const handleRewind = () => {
      if (videoRef.current) {
        videoRef.current.currentTime -= 3;
        setProgress(
          videoRef.current.currentTime / videoRef.current.duration * 100
        );
        if (rest.onRewind)
          rest.onRewind();
      }
    };
    const handleVolumeUp = () => {
      if (videoRef.current) {
        const newVolume = Math.min(volume + 0.1, 1);
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
        if (rest.onVolumeUp)
          rest.onVolumeUp();
      }
    };
    const handleVolumeDown = () => {
      if (videoRef.current) {
        const newVolume = Math.max(volume - 0.1, 0);
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
        if (rest.onVolumeDown)
          rest.onVolumeDown();
      }
    };
    const handleVolume = (newVolume) => {
      if (videoRef.current) {
        setVolume(newVolume);
        videoRef.current.volume = newVolume;
        if (rest.onVolumeChange)
          rest.onVolumeChange(newVolume);
      }
    };
    const handleFullScreen = () => {
      if (videoRef.current) {
        videoRef.current.requestFullscreen();
        if (rest.onFullScreen)
          rest.onFullScreen();
      }
    };
    const handleProgress = () => {
      if (!isDragging && videoRef.current) {
        const currentTime2 = videoRef.current.currentTime;
        const duration2 = videoRef.current.duration;
        if (!isNaN(duration2) && duration2 > 0) {
          const progressPercentage = currentTime2 / duration2 * 100;
          setProgress(progressPercentage);
        }
      }
    };
    const handleDragStart = () => {
      setIsDragging(true);
    };
    const handleDragEnd = (event) => {
      if (videoRef.current) {
        const progressBar = event.currentTarget;
        const clickPosition = event.type === "touchend" ? event.changedTouches[0].clientX - progressBar.getBoundingClientRect().left : event.clientX - progressBar.getBoundingClientRect().left;
        const progressPercentage = clickPosition / progressBar.offsetWidth * 100;
        setProgress(progressPercentage);
        videoRef.current.currentTime = progressPercentage / 100 * videoRef.current.duration;
      }
      setIsDragging(false);
    };
    const handleTogglePictureInPicture = () => {
      if (videoRef.current) {
        if (document.pictureInPictureElement) {
          document.exitPictureInPicture().then(() => setIsPictureInPicture(false)).catch((error) => console.error("Error exiting Picture-in-Picture mode:", error));
        } else {
          videoRef.current.requestPictureInPicture().then(() => setIsPictureInPicture(true)).catch((error) => console.error("Error entering Picture-in-Picture mode:", error));
        }
        if (rest.onTogglePictureInPicture)
          rest.onTogglePictureInPicture();
      }
    };
    return /* @__PURE__ */ React3.createElement("div", { className: "sk-video-player  bg-red-400	 relative", style: { width, height } }, /* @__PURE__ */ React3.createElement(
      "video",
      {
        ref: (videoElement) => {
          if (videoElement) {
            videoRef.current = videoElement;
            if (typeof ref === "function") {
              ref(videoElement);
            } else if (ref) {
              ref.current = videoElement;
            }
          }
        },
        src,
        className: "w-full h-full",
        onTimeUpdate: handleProgress,
        onEnded: () => setIsPlaying(false)
      }
    ), showControls && /* @__PURE__ */ React3.createElement("div", { className: "absolute bottom-15 z-998 left-0 right-0 flex justify-center space-x-4" }, /* @__PURE__ */ React3.createElement("button", { className: "cursor-pointer text-ccc", onClick: handlePlayPause }, isPlaying ? "Pause" : "Play"), /* @__PURE__ */ React3.createElement("button", { className: "cursor-pointer text-ccc", onClick: handleReload }, "Reload"), /* @__PURE__ */ React3.createElement("button", { className: "cursor-pointer text-ccc", onClick: handleFastForward }, "Fast Forward"), /* @__PURE__ */ React3.createElement("button", { className: "cursor-pointer text-ccc", onClick: handleRewind }, "Rewind"), /* @__PURE__ */ React3.createElement("button", { onClick: handleVolumeUp }, "Volume Up"), /* @__PURE__ */ React3.createElement("button", { onClick: handleVolumeDown }, "Volume Down"), /* @__PURE__ */ React3.createElement("button", { onClick: () => handleVolume(0) }, "Mute"), /* @__PURE__ */ React3.createElement("button", { className: "cursor-pointer text-ccc", onClick: handleFullScreen }, "Full Screen"), /* @__PURE__ */ React3.createElement("button", { className: "cursor-pointer text-ccc", onClick: handleTogglePictureInPicture }, isPictureInPicture ? "Exit Picture-in-Picture" : "Enter Picture-in-Picture")), /* @__PURE__ */ React3.createElement("div", { className: "absolute bottom-5 left-0 right-0 mb-4 px-4  z-999" }, /* @__PURE__ */ React3.createElement(
      "progress",
      {
        value: progress,
        max: 100,
        onMouseDown: handleDragStart,
        onMouseUp: handleDragEnd,
        onTouchStart: handleDragStart,
        onTouchEnd: handleDragEnd,
        className: "w-full h-2 bg-gray-300 rounded-full overflow-hidden cursor-pointer"
      }
    )), /* @__PURE__ */ React3.createElement("div", { className: "absolute bottom-0 left-0 right-0 mb-2 px-4 flex justify-between text-ccc" }, /* @__PURE__ */ React3.createElement("span", null, currentTime), /* @__PURE__ */ React3.createElement("span", null, duration)));
  }
);
var VideoPlayer_default = VideoPlayer;

// src/VideoPlayer/index.tsx
var VideoPlayer_default2 = VideoPlayer_default;

// src/Calendar/Calendar.tsx
import dayjs from "dayjs";
import React6, { useState as useState2 } from "react";
import cs2 from "classnames";

// src/Calendar/MonthCalendar.tsx
import React4, { useContext } from "react";
import cs from "classnames";

// src/Calendar/LocaleContext.tsx
import { createContext } from "react";
var LocaleContext = createContext({
  locale: "zh-CN"
});
var LocaleContext_default = LocaleContext;

// src/Calendar/locale/zh-CN.ts
var CalendarLocale = {
  formatYear: "YYYY 年",
  formatMonth: "YYYY 年 MM 月",
  today: "今天",
  month: {
    January: "一月",
    February: "二月",
    March: "三月",
    April: "四月",
    May: "五月",
    June: "六月",
    July: "七月",
    August: "八月",
    September: "九月",
    October: "十月",
    November: "十一月",
    December: "十二月"
  },
  week: {
    monday: "周一",
    tuesday: "周二",
    wednesday: "周三",
    thursday: "周四",
    friday: "周五",
    saturday: "周六",
    sunday: "周日"
  }
};
var zh_CN_default = CalendarLocale;

// src/Calendar/locale/en-US.ts
var CalendarLocale2 = {
  formatYear: "YYYY",
  formatMonth: "MMM YYYY",
  today: "Today",
  month: {
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December"
  },
  week: {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday"
  }
};
var en_US_default = CalendarLocale2;

// src/Calendar/locale/index.ts
var allLocales = {
  "zh-CN": zh_CN_default,
  "en-US": en_US_default
};
var locale_default = allLocales;

// src/Calendar/MonthCalendar.tsx
function getAllDays(date) {
  const startDate = date.startOf("month");
  const day = startDate.day();
  const daysInfo = Array.from({
    length: 6 * 7
  });
  for (let i = 0; i < day; i++) {
    daysInfo[i] = {
      date: startDate.subtract(day - i, "day"),
      currentMonth: false
    };
  }
  for (let i = day; i < daysInfo.length; i++) {
    const calcDate = startDate.add(i - day, "day");
    daysInfo[i] = {
      date: calcDate,
      currentMonth: calcDate.month() === date.month()
    };
  }
  return daysInfo;
}
function MonthCalendar(props) {
  const localeContext = useContext(LocaleContext_default);
  const { value, curMonth, dateRender, dateInnerContent, selectHandler } = props;
  const CalendarLocale3 = locale_default[localeContext.locale];
  const weekList = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday"
  ];
  const allDays = getAllDays(curMonth);
  function renderDays(days) {
    const rows = [];
    for (let i = 0; i < 6; i++) {
      const row = [];
      for (let j = 0; j < 7; j++) {
        const item = days[i * 7 + j];
        row[j] = /* @__PURE__ */ React4.createElement(
          "div",
          {
            key: `${item.date.format("YYYY-MM-DD")}-${i}-${j}`,
            className: `calendar-month-body-cell ${item.currentMonth ? "calendar-month-body-cell-current" : ""}`,
            onClick: () => selectHandler == null ? void 0 : selectHandler(item.date)
          },
          dateRender ? dateRender(item.date) : /* @__PURE__ */ React4.createElement("div", { className: "calendar-month-body-cell-date" }, /* @__PURE__ */ React4.createElement(
            "div",
            {
              className: cs(
                "calendar-month-body-cell-date-value",
                value.format("YYYY-MM-DD") === item.date.format("YYYY-MM-DD") ? "calendar-month-body-cell-date-selected" : ""
              )
            },
            item.date.date()
          ), /* @__PURE__ */ React4.createElement("div", { className: "calendar-month-cell-body-date-content" }, dateInnerContent == null ? void 0 : dateInnerContent(item.date)))
        );
      }
      rows.push(row);
    }
    return rows.map((row, index) => (
      // eslint-disable-next-line react/no-array-index-key
      /* @__PURE__ */ React4.createElement("div", { className: "calendar-month-body-row", key: index }, row)
    ));
  }
  return /* @__PURE__ */ React4.createElement("div", { className: "calendar-month" }, /* @__PURE__ */ React4.createElement("div", { className: "calendar-month-week-list" }, weekList.map((week) => /* @__PURE__ */ React4.createElement("div", { className: "calendar-month-week-list-item", key: week }, CalendarLocale3.week[week]))), /* @__PURE__ */ React4.createElement("div", { className: "calendar-month-body" }, renderDays(allDays)));
}
var MonthCalendar_default = MonthCalendar;

// src/Calendar/Header.tsx
import React5, { useContext as useContext2 } from "react";
function Header(props) {
  const { curMonth, prevMonthHandler, nextMonthHandler, todayHandler } = props;
  const localeContext = useContext2(LocaleContext_default);
  const CalendarContext = locale_default[localeContext.locale];
  return /* @__PURE__ */ React5.createElement("div", { className: "calendar-header" }, /* @__PURE__ */ React5.createElement("div", { className: "calendar-header-left" }, /* @__PURE__ */ React5.createElement("div", { className: "calendar-header-icon", onClick: prevMonthHandler }, "<"), /* @__PURE__ */ React5.createElement("div", { className: "calendar-header-value" }, curMonth.format(CalendarContext.formatMonth)), /* @__PURE__ */ React5.createElement("div", { className: "calendar-header-icon", onClick: nextMonthHandler }, ">"), /* @__PURE__ */ React5.createElement("button", { className: "calendar-header-btn", onClick: todayHandler }, CalendarContext.today)));
}
var Header_default = Header;

// src/Calendar/Calendar.tsx
function Calendar(props) {
  const { value, style, className, locale, onChange } = props;
  const [curValue, setCurValue] = useState2(value);
  const [curMonth, setCurMonth] = useState2(value);
  const classNames3 = cs2("calendar", className);
  function selectHandler(date) {
    setCurValue(date);
    setCurMonth(date);
    onChange == null ? void 0 : onChange(date);
  }
  function prevMonthHandler() {
    setCurMonth(curMonth.subtract(1, "month"));
  }
  function nextMonthHandler() {
    setCurMonth(curMonth.add(1, "month"));
  }
  function todayHandler() {
    const date = dayjs(Date.now());
    setCurValue(date);
    setCurMonth(date);
    onChange == null ? void 0 : onChange(date);
  }
  return /* @__PURE__ */ React6.createElement(
    LocaleContext_default.Provider,
    {
      value: {
        locale: locale || navigator.language
      }
    },
    /* @__PURE__ */ React6.createElement("div", { className: classNames3, style }, /* @__PURE__ */ React6.createElement(
      Header_default,
      {
        curMonth,
        prevMonthHandler,
        nextMonthHandler,
        todayHandler
      }
    ), /* @__PURE__ */ React6.createElement(
      MonthCalendar_default,
      __spreadProps(__spreadValues({}, props), {
        value: curValue,
        curMonth,
        selectHandler
      })
    ))
  );
}
var Calendar_default = Calendar;

// src/Calendar/index.tsx
var Calendar_default2 = Calendar_default;

// src/Monitor/Monitor.tsx
import React11 from "react";

// src/Monitor/MonitorContext.tsx
import React7, { createContext as createContext2, useContext as useContext3, useState as useState3 } from "react";
var MonitorContext = createContext2(
  void 0
);
var useMonitorContext = () => {
  const context = useContext3(MonitorContext);
  if (!context) {
    throw new Error(
      "useMonitorContext must be used within a MonitorContextProvider"
    );
  }
  return context;
};
var MonitorContextProvider = ({
  children,
  config
}) => {
  const [monitorType, setMonitorType] = useState3("none");
  return /* @__PURE__ */ React7.createElement(MonitorContext.Provider, { value: { monitorType, setMonitorType, config } }, children);
};
var MonitorContext_default = MonitorContextProvider;

// src/Monitor/compontns/Fundebug/Fundebug.tsx
import fundebug from "fundebug-javascript";
import "fundebug-revideo";
import React8 from "react";
var FundebugMonitor = class extends React8.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  componentDidMount() {
    const { fundebugKey, debug } = this.props;
    if (debug) {
      console.log("开启调试模式");
    }
    if (fundebugKey) {
      console.log(`fundebugKey: ${fundebugKey}`);
      fundebug.init({
        apikey: fundebugKey
      });
      console.log("fundebug 初始化成功");
    }
  }
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    fundebug.notifyError(error, {
      metaData: {
        info: info.componentStack
      }
    });
  }
  render() {
    if (this.state.hasError) {
      return null;
    }
    return /* @__PURE__ */ React8.createElement(React8.Fragment, null, this.props.children);
  }
};
var Fundebug_default = FundebugMonitor;

// src/Monitor/compontns/Fundebug/index.tsx
var Fundebug_default2 = Fundebug_default;

// src/Monitor/compontns/Rollbar/Rollbar.tsx
import { ErrorBoundary, Provider } from "@rollbar/react";
import React9 from "react";
var RollbarMonitor = ({
  children,
  rollbarKey,
  rollbarEnv,
  debug
}) => {
  if (debug) {
    console.log("开启调试模式");
    console.log(`rollbarKey: ${rollbarKey}`);
  }
  const config = {
    accessToken: rollbarKey || "23b8e46f18bd4eecaee8855d44148de4",
    environment: rollbarEnv || "testenv"
  };
  return /* @__PURE__ */ React9.createElement(Provider, { config }, /* @__PURE__ */ React9.createElement(ErrorBoundary, null, children));
};
var Rollbar_default = RollbarMonitor;

// src/Monitor/compontns/Rollbar/index.tsx
var Rollbar_default2 = Rollbar_default;

// src/Monitor/compontns/Sentry/Sentry.tsx
import * as Sentry from "@sentry/react";
import React10, { useLayoutEffect } from "react";
function FallbackComponent() {
  return /* @__PURE__ */ React10.createElement("h2", null, "Something went wrong.");
}
function useLInitSentry(options) {
  useLayoutEffect(() => {
    if (!sessionStorage.getItem("sentryInitialized")) {
      Sentry.init(__spreadValues({
        integrations: [
          // eslint-disable-next-line import/namespace
          Sentry.browserTracingIntegration(),
          // eslint-disable-next-line import/namespace
          Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false
          })
        ],
        tracesSampleRate: 1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1,
        maxBreadcrumbs: 50
      }, options));
      sessionStorage.setItem("sentryInitialized", "true");
    }
    return () => {
    };
  }, [options]);
}
var SentryMonitor = ({
  children,
  sentryOptions,
  fallback
}) => {
  useLInitSentry(sentryOptions);
  return /* @__PURE__ */ React10.createElement(Sentry.ErrorBoundary, { fallback: fallback || FallbackComponent }, children);
};
var Sentry_default = SentryMonitor;

// src/Monitor/compontns/Sentry/index.tsx
var Sentry_default2 = Sentry_default;

// src/Monitor/Monitor.tsx
var Monitor = ({
  fundebug: fundebug2,
  rollbar,
  sentry,
  children,
  className,
  config
}) => {
  const { monitorType, config: monitorConfig } = useMonitorContext();
  const configValue = __spreadValues(__spreadValues({}, monitorConfig), config);
  const monitorContent = (() => {
    switch (monitorType) {
      case "fundebug":
        return /* @__PURE__ */ React11.createElement(Fundebug_default2, __spreadValues({}, configValue), fundebug2 || children);
      case "rollbar":
        return /* @__PURE__ */ React11.createElement(Rollbar_default2, __spreadValues({}, configValue), rollbar || children);
      case "sentry":
        return /* @__PURE__ */ React11.createElement(Sentry_default2, __spreadValues({}, configValue), sentry || children);
      default:
        return /* @__PURE__ */ React11.createElement(Sentry_default2, __spreadValues({}, configValue), /* @__PURE__ */ React11.createElement(Fundebug_default2, __spreadValues({}, configValue), fundebug2), /* @__PURE__ */ React11.createElement(Rollbar_default2, __spreadValues({}, configValue), rollbar), sentry || children);
    }
  })();
  return /* @__PURE__ */ React11.createElement("div", { className }, monitorContent);
};
var Monitor_default = Monitor;

// src/Monitor/index.tsx
var Monitor_default2 = Monitor_default;
export {
  Button_default2 as Button,
  Calendar_default2 as Calendar,
  Divider_default2 as Divider,
  Monitor_default2 as Monitor,
  MonitorContext_default as MonitorContextProvider,
  VideoPlayer_default2 as VideoPlayer
};
